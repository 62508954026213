@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root{
  --yellowColor:#e1f296;
  --lightGreenColor:#5aba8a;
  --darkGreenColor:#01565b;
  --transparentDarkGreenColor:#00a1aa;
  --textColor:#044561;
  --h1Color:#012e33;
  --cardColor:#4c9fa7;
  --whiteColor:hsl(0,0%,100%);
  --greyText:rgb(190,190,190);
  --inputColor:hsl(224,35%,57%);
  --darkColor:#000;
  --lightBackground:rgb(238, 238, 238);

  --h1FontSize:2.5rem;
  --h2FontSize:1.25rem;
  --h3FontSize:1rem;
  --normalFontSize:9.38rem;
  --smallFontSize: .813rem;
  --smallestFontSize:0.75rem;
}

body {
  align-items: center;
  justify-content: center;
  margin: 0;
  padding:0;
  font-family: "Sigmar", serif;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  box-sizing: content-box;
  overflow: hidden;

  .row{
    display: flex;
    padding: 1rem 0;
  }

  .col{
    display: block;
    padding: 0 1rem;
  }

  .justify-center{
    justify-content: center;
  }
}