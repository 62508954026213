.mobile{
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    color: var(--darkGreenColor);
    flex-direction: column;
    position: relative;
    display: flex;
    max-width: 500px;
    overflow: hidden;

    .main-menu{
        position: absolute;
        display: block;
        align-content: center;
        height: 100vh;
        width: 100vw;
        background-color: #ffffffdf;
        z-index: 100;
        justify-content: center;
        align-items: center;
        animation: ScrollDown 1s;
        border-bottom-left-radius: 2rem;
        border-bottom-right-radius: 2rem;

        .menu-close-icon{
            top: 1rem;
            left: 1rem;
            padding: .75rem;
            font-size: 1.5rem;
            border-radius: 1rem;
            position: absolute;
            border: 1px solid var(--darkGreenColor);
        }

        .options{
            display: grid;
            text-align: center;

            .menu-link{
                margin: .5rem 0;
                font-weight: 800;
                font-size: 1.25rem;
                text-decoration: none;
                color: var(--darkGreenColor);
            }
        }

    }

    .header{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        .menu-btn{
            border: none;
            margin: .5rem 1rem;
            background: var(--darkGreenColor);
            padding: .25rem .35rem;
            border-radius: .5rem;

            .menu-icon{
                font-size: 2rem;
                color: #fff;
            }
        }
    }

    .main{
        flex: 3;
        margin: 0 1rem;
        display: block;
        user-select: none;
        height: 100%;
        text-align: start;
        align-content: center;

        h1{
            font-size: 4rem;
            font-weight: 900;
            line-height: 3.25rem;
        }
    }

    .footer{
        width: 100%;
        flex: 1;
        border-top-left-radius: 3rem;
        border-top-right-radius: 3rem;
        display: block;
        align-content: center;
        background-color: var(--darkGreenColor);
        
        button{
            color: #000;
            font-weight: 600;
            background-color: var(--yellowColor);
            border: none;
            margin: 1rem 0;
            padding: 1rem 1.25rem;
            border-radius: 1rem;
            font-size: 1.25rem;
            text-transform: uppercase;
        }
    }
}

.wizard{
    animation: ScrollDown 1s;
    display: block;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    color: var(--darkGreenColor);
    flex-direction: column;
    position: relative;
    display: flex;
    overflow: hidden;

    .header{
        width: 100%;
        display: grid;
        border-bottom: 1px solid var(--darkGreenColor);
        text-align: center;
        grid-template-columns: repeat(3,1fr);
        flex: 1;
        align-items: center;
        justify-items: center;
        position: relative;

        
        span{
            line-height: 1rem;
        }

        .wheader-icons{
            margin: 0 1rem;
            font-size: 1.25rem;
            padding: .5rem;
            cursor: pointer;
        }

        .stepper-progress{
            position: absolute;
            width: var(--progress);
            border-top: 4px solid var(--darkGreenColor);
            bottom: 0;
            left: 0;
            transform: translateY(50%);
        }
    }

    .main{
        flex: 9;
        margin: 0 1rem;
        display: block;
        user-select: none;
        height: 100%;
        text-align: start;
        align-content: center;
        text-align: center;
        
        .heading-text{
            .stepper{
                font-weight: 100;
                margin-bottom: 1rem;
            }
    
            .title{
                margin: 0 1rem;
                color: var(--darkGreenColor);
                line-height: 1.75rem;
            }
    
            .subtitle{
                margin: 1rem 0 0 0;
                line-height: 2.5rem;
            }
        }

        .grid{
            display: grid;
            grid-template-columns: repeat(3,1fr);
            justify-items: center;
            margin-top: 2rem;
            gap: 5px;

            button.option{
                display: flex;
                height: 4rem;
                width: 4rem;
                justify-content: center;
                align-items: center;
                font-size: 1.125rem;
                color: var(--darkGreenColor);

                .option-icon{
                    color: var(--darkGreenColor) !important;
                    font-size: 3rem;
                }
            }
        }

        .picker-container{
            margin-top: 2rem;
            justify-items: center;
            
            .selection{
                width: 125px;
                border: none;
                height: 2.75rem;
                font-size: 1.125rem;
                font-weight: 600;
                color: var(--darkGreenColor);

                .active{
                    font-size: 1.5rem;
                }
            }

            .court{
                width: 250px;
                border-radius: .75rem;
            }

            .picker-control{
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--darkGreenColor);

                button.picker{
                    display: flex;
                    height: 2.5rem;
                    width: 2.5rem;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    color: var(--darkGreenColor);
                    
                    .icon-picker{
                        font-size: 2rem;
                    }
    
                    .option-icon{
                        color: var(--darkGreenColor) !important;
                        font-size: 3rem;
                    }
                }
            }
        }
    }

    .footer{
        width: 100%;
        flex: 1;
        border-top-left-radius: 3rem;
        border-top-right-radius: 3rem;
        display: block;
        align-content: center;
        justify-content: center;
        background-color: var(--darkGreenColor);
        
        button{
            color: #000;
            font-weight: 600;
            background-color: var(--yellowColor);
            border: none;
            margin: 1rem 0;
            padding: 1rem 1.25rem;
            border-radius: 1rem;
            font-size: 1.25rem;
            text-transform: uppercase;
        }
    }

    .options-menu{
        position: absolute;
        display: flex;
        align-content: center;
        height: 100vh;
        width: 100vw;
        background-color: #ffffffdf;
        z-index: 100;
        justify-content: center;
        align-items: center;
        animation: ScrollDown 1s;
        border-bottom-left-radius: 2rem;
        border-bottom-right-radius: 2rem;
        overflow: scroll;

        .options{

            .court{
                border: none;
                color: var(--darkGreenColor);
                padding: 1rem;
                border-radius: .5rem;
                margin: .25rem 0;

                p{
                    margin: 0;
                }

                .court-name{
                    font-weight: 800;
                    text-transform: uppercase;
                }

                .address{
                    font-weight: lighter;
                }
            }
        }
    }
}

@keyframes ScrollDown {
    0%{
        opacity: 0;
        transform: translateY(-100%);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}

